import React from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Wrapper } from "./styles"
import { Section } from "../containers"
import { H3, Container } from "../elements"

export const ContactForm = () => {
  return (
    <Wrapper>
      <Section>
        <Container>
          <H3 className="mb-5">Contactez-nous</H3>
          <Row>
            <Col lg={6}>
              <strong>Tel.</strong>
              <p>+33 6 59 22 71 60</p>
              <br />
              <strong>E-mail</strong>
              <p>contact@aerotig.com</p>
              <br />
              <strong>Adresse</strong>
              <p>
                Chemin des hoches <br /> 08160 - Dom-le-mesnil
              </p>
              {/* <Leaflet /> */}

              {/* <Col
                lg={6}
                className="d-flex justify-content-lg-center mb-5 mt-5 m-lg-0"
              >
                <div>
                  <strong>Social</strong>
                  <p>Linkedin</p>
                </div>
              </Col> */}
            </Col>
            <Col lg={6}>
              <strong>Ou vous pouvez nous écrire</strong>

              <form
                method="POST"
                data-netlify="true"
                name="contact"
                id="contact"
              >
                <input type="hidden" name="form-name" value="contact" />
                <Form.Group>
                  <Form.Label htmlFor="type">Type de demande</Form.Label>
                  <Form.Control id="type" name="type" as="select">
                    <option>Devis</option>
                    <option>Informations</option>
                  </Form.Control>
                </Form.Group>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label htmlFor="name">Nom</Form.Label>
                      <Form.Control
                        id="name"
                        required
                        type="text"
                        name="name"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label htmlFor="fistname">Prénom</Form.Label>
                      <Form.Control
                        id="fistname"
                        required
                        type="text"
                        name="firstname"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label htmlFor="phone">Téléphone</Form.Label>
                      <Form.Control
                        id="phone"
                        required
                        type="tel"
                        name="phone"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label htmlFor="email">Email</Form.Label>
                      <Form.Control
                        id="email"
                        required
                        type="email"
                        name="email"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label htmlFor="message">Message</Form.Label>
                  <Form.Control
                    id="message"
                    required
                    as="textarea"
                    rows="8"
                    name="message"
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Envoyer
                </Button>
              </form>
            </Col>
          </Row>
        </Container>
      </Section>
    </Wrapper>
  )
}
