import styled from "styled-components"

export const Wrapper = styled.div`
  color: ${props => props.theme.colors.text};

  form {
    font-size: 1.8rem;
    margin-top: 20px;
    .form-label {
      font-size: 1.9rem;
      color: ${props => props.theme.colors.text};
    }
    input,
    textarea,
    select {
      min-height: 50px;
      border-radius: 0;
      border-color: #dedede;
      font-size: 1.6rem;
    }
    .btn-primary {
      background-color: ${props => props.theme.colors.primary} !important;
      border: none !important;
      border-radius: 0;
      font-size: 1.5rem;
      margin-top: 20px;
      padding: 15px 60px;
      &:hover,
      &:focus,
      &:active {
        background-color: ${props => props.theme.colors.primary};
        border: none;
        box-shadow: none !important;
      }
      &:active {
        opacity: 0.8;
      }
    }
  }
`
